<template>
  <div>
        <div class="reader">
            <video
                id="js-video"
                class="reader-video"
                autoplay
                playsinline
            ></video>
        </div>

        <div class="reticle">
            <div class="reticle-box"></div>
        </div>

        <div style="display: none">
            <canvas id="js-canvas"></canvas>
        </div>

        <div id="js-modal" class="modal-overlay">
            <div class="modal">
                <div class="modal-cnt">
                    <span class="modal-title">読み取り結果</span>
                    <textarea
                        id="js-result"
                        class="modal-result"
                        value=""
                        readonly
                    />
                </div>
                <a href="" id="js-link" class="modal-btn" target="_blank">
                    開く
                </a>
                <button id="js-copy" class="modal-btn" target="_blank">
                    コピー
                </button>
                <button type="button" id="js-modal-close" class="modal-btn">
                    閉じる
                </button>
            </div>
        </div>

        <div id="js-unsupported" class="unsupported">
            <p class="unsupported-title">Sorry!</p>
            <p>Unsupported browser</p>
        </div>
    
  </div>
</template>
<script>
export default {
  name: 'MediaDevices',
  props: {
    msg: String
  },
  mounted() {
    // require('../libs/app')
    window.SQR = window.SQR || {}

    // eslint-disable-next-line no-undef
    SQR.reader = (() => {
        /**
         * getUserMedia()に非対応の場合は非対応の表示をする
         */
        const showUnsuportedScreen = () => {
            document.querySelector('#js-unsupported').classList.add('is-show')
        }
        if (!navigator.mediaDevices) {
            showUnsuportedScreen()
            return
        }

        const video = document.querySelector('#js-video')

        /**
         * videoの出力をCanvasに描画して画像化 jsQRを使用してQR解析
         */
        const checkQRUseLibrary = () => {
            const canvas = document.querySelector('#js-canvas')
            const ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
            // eslint-disable-next-line no-undef
            const code = window.jsQR(imageData.data, canvas.width, canvas.height)
            console.log("🚀 ~ file: MediaDevices.vue:85 ~ checkQRUseLibrary ~ jsQR:", window.jsQR)

            if (code) {
                // eslint-disable-next-line no-undef
                SQR.modal.open(code.data)
            } else {
                setTimeout(checkQRUseLibrary, 200)
            }
        }

        /**
         * videoの出力をBarcodeDetectorを使用してQR解析
         */
        const checkQRUseBarcodeDetector = () => {
            // eslint-disable-next-line no-undef
            const barcodeDetector = new BarcodeDetector()
            barcodeDetector
                .detect(video)
                .then((barcodes) => {
                    if (barcodes.length > 0) {
                        for (let barcode of barcodes) {
                            // eslint-disable-next-line no-undef
                            SQR.modal.open(barcode.rawValue)
                        }
                    } else {
                        setTimeout(checkQRUseBarcodeDetector, 200)
                    }
                })
                .catch(() => {
                    console.error('Barcode Detection failed, boo.')
                })
        }

        /**
         * BarcodeDetector APIを使えるかどうかで処理を分岐
         */
        const findQR = () => {
            window.BarcodeDetector
                ? checkQRUseBarcodeDetector()
                : checkQRUseLibrary()
        }

        /**
         * デバイスのカメラを起動
         */
        const initCamera = () => {
            navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                        facingMode: {
                            exact: 'environment',
                        },
                    },
                })
                .then((stream) => {
                    video.srcObject = stream
                    video.onloadedmetadata = () => {
                        video.play()
                        findQR()
                    }
                })
                .catch(() => {
                    showUnsuportedScreen()
                })
        }

        return {
            initCamera,
            findQR,
        }
    })()

    // eslint-disable-next-line no-undef
    SQR.modal = (() => {
        const result = document.querySelector('#js-result')
        const link = document.querySelector('#js-link')
        const copyBtn = document.querySelector('#js-copy')
        const modal = document.querySelector('#js-modal')
        const modalClose = document.querySelector('#js-modal-close')

        /**
         * 取得した文字列を入れ込んでモーダルを開く
         */
        const open = (url) => {
            result.value = url
            link.setAttribute('href', url)
            modal.classList.add('is-show')
        }

        /**
         * モーダルを閉じてQR読み込みを再開
         */
        const close = () => {
            modal.classList.remove('is-show')
            // eslint-disable-next-line no-undef
            SQR.reader.findQR()
        }

        const copyResultText = () => {
            result.select()
            document.execCommand('copy')
        }

        copyBtn.addEventListener('click', copyResultText)

        modalClose.addEventListener('click', () => close())

        return {
            open,
        }
    })()

    // eslint-disable-next-line no-undef
    if (SQR.reader) SQR.reader.initCamera()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

</style>
